.navbar{
    background-color: var(--bg-secondary);
    position: fixed;
    transition: width 200ms ease;
    z-index: 1;
}

.navbar-nav{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.logo{
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    text-align: center;
    color: var(--text-primary);
    /* background-color: var(--bg-primary); */
    font-size: 1.5rem;
    letter-spacing: 0.3ch;
    width: 100%;
}

.logo svg {
    transform: rotate(180deg);
    transition: transform var(--transition-speed);
}

.navbar:hover .logo svg{
    transform: rotate(0deg);
}

.nav-item{
    width: 100%;
}

.nav-item:last-child{
    margin-top: auto;
}

.nav-link{
    display: flex;
    align-items: center;
    height: 5rem;
    color: var(--text-secondary);
    text-decoration: none;
    filter: grayscale(100%) opacity(0.7);
    transition: var(--transition-speed);
}

.nav-link:active{
    filter: grayscale(0%) opacity(1);
    background: var(--bg-primary);
    color: var(--text-primary);
}

.nav-link-active{
    filter: grayscale(0%) opacity(1);
    background: var(--bg-primary);
    color: var(--text-primary);
}

.link-text{
    display: none;
    margin-left: 1rem;
}

.nav-link svg{
    width: 2rem;
    margin: 0 1.5rem;
}

.navbar-icon{
    color: var(--pop-color);
    transition: var(--transition-speed);
}

@media (hover: hover) and (pointer: fine) {
    .nav-link:hover{
        filter: grayscale(0%) opacity(1);
        background: var(--bg-primary);
        color: var(--text-primary);
    }
  }

/* Small Screens */
@media only screen and (max-width: 600px) {
    .navbar{
        bottom: 0;
        width: 100vw;
        height: 5rem;
    }

    .logo{
        display: none;
    }

    .navbar-nav{
        display: flex;
        flex-direction: row;
    }

    .nav-link{
        justify-content: center;
    }
    
}

/* Mobile Landscape  */
@media only screen and (max-device-height: 479px)
    and (orientation: landscape) {

    .navbar{
        bottom: 0;
        width: 100vw;
        height: 5rem;
    }

    .logo{
        display: none;
    }

    .navbar-nav{
        display: flex;
        flex-direction: row;
    }

    .nav-link{
        justify-content: center;
    }
}


/* Large Screens */
@media only screen and (min-width: 600px) and (min-device-height: 480px) {
    .navbar{
        top: 0;
        width: 5rem;
        height: 100vh;
    }

    .navbar:hover{
        width: 16rem;
    }

    .navbar:hover .link-text{
        display: inline;
        transition: opacity var(--transition-speed);
    }

    .navbar:hover ~ main {
        margin-left: 16rem;   
    }

    .navbar:hover ~ footer {
        padding-left: 16rem;   
    }
}

.theme-icon {
    display: none;
}
  
.dark #darkIcon {
    display: block;
}

.light #lightIcon {
    display: block;
}

.solar #solarIcon {
    display: block;
}
