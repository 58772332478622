#blog_main{
    margin: 0 25% 0 25%;
}

.blog_link{
    background-color: var(--bg-secondary);
    border-radius: 10px;
    display: flex;
    margin: 10px 0 10px 0;
    text-decoration: none;
    height: 220px;
    overflow: hidden;
    padding: 10px;
    /* text-overflow: ellipsis; */
    /* white-space: nowrap; */
}

.blog_link img{
    width: 250px;
    margin-right: 10px;
}

.blog_link p, .blog_link h3, .blog_subtitle{
    color: var(--text-primary);
    text-decoration: none;
    margin-top: 0;
}

.code_title{
    background: var(--bg-secondary);
    display: inline-block;
    border-radius: 10px;
    padding: 5px;
    max-width: 100%;
    word-wrap: break-word;
}

.blog_img{
    max-width: 100%;
    max-height: calc(50vh + 180px);
    border-radius: 5px;
}

.paragraph{
    margin: 20px 0 20px 0;
}



/* Small Screens */
@media only screen and (max-width: 1000px){

    #blog_main{
        margin: 0;
    }
    
}

@media only screen and (max-height: 590px){

    #blog_main{
        margin: 0;
    }

}

/* Mobile Landscape  */
@media only screen and (max-device-height: 480px) 
    and (orientation: landscape) {

    #blog_main{
        margin: 0;
    }

}