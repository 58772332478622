#resume .socials{
    justify-content: start;
    margin-bottom: 1rem;
}

#resume h3{
    margin-bottom: 0;
}

#resume .email{
    margin: 0;
}

#resume-content{
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
}

#resume-content h2{
    letter-spacing: 10px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: bold;
}

.content{
    margin-bottom: 2rem;
}

.content-title{
    text-align: left;
    margin-top: 1rem;
}

.content-title p{
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

.content-title span{
    margin: 0 0 0 .2rem;
    font-size: 12px;
    font-style: italic;
    font-weight: normal;
}

.content-body ul{
    padding-left: 2rem;
    margin: .5rem 0;
}

.content-body li{
    text-align: justify;
}

#skills .content-body{
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#skills p {
    padding: 0;
    margin: .2rem 0;
    width: 10rem;
}

/* Small Screens */
@media only screen and (max-width: 600px){
    #resume-content {
        padding: 0;
    }
}
