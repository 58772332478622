#aboutMe .title{
    display: inline-block;
}

#aboutMe h1{
    margin-bottom: 0;
    letter-spacing: 2px;
}

#aboutMe h3{
    margin-top: 0;
    letter-spacing: 2px;
    text-align: end;
    font-style: italic;
}

#aboutMe img {
    width: 20rem;
    height: 20rem;
    object-fit: cover;
    object-position: 100% 0;
    border-radius: 10rem;
    margin-right: 2rem;
}

#aboutMe .info{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
}

#aboutMe .info p{
    max-width: 50rem;
}

.cvButton{
    display: flex;
    align-items: center;
    color: var(--text-primary);
    text-decoration: none;
    background-color: var(--bg-secondary);
    border-radius: 2rem;
    padding: 0 1rem;
    max-width: 12rem;
    transition: var(--transition-speed);
}

.cvButton svg{
    width: 1.2rem;
    margin-right: .5rem;
}

.cvButton:hover{
    background-color: var(--pop-color);
}

/* Small Screens */
@media only screen and (max-width: 600px){
    #aboutMe .info {
        flex-direction: column;
    }

    #aboutMe .info2{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 300px){
    #aboutMe img {
        width: 15rem;
        height: 15rem;
        margin: auto;
    }
}

@media only screen and (max-height: 400px){
    #aboutMe img {
        width: 10rem;
        height: 10rem;
    }
}