.socials{
    display: flex;
    align-items: center;
    justify-content: center;
}

.socials a {
    width: 1.5rem;
    padding: 0 1rem;
    color: var(--text-fade);
    
}

.socials a:hover, .me a:hover {
    color: var(--pop-color);
}

footer hr {
    width: 5rem;
    border: 0;
    border-top: 1px dashed var(--text-fade);
}

.copyright{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.copyright p {
    margin: 0;
    font-size: .8rem;
    color: var(--text-fade);
}

.copyright .me{
    display: flex;
    align-items: center;
}

.me a{
    margin-left: 5px;
    text-decoration: none;
    color: var(--text-fade);
    font-size: .9rem;
}

